<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Coupon</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                            <form @submit.prevent="postCoupon" class="row p-2">
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Coupon Code</label>
                                    <input v-model="form.code" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Coupon Amount</label>
                                    <input v-model="form.quota" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-12">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colspan="2">Regular</th>
                                                <th colspan="2">Special Features</th>
                                            </tr>
                                        </thead>
                                        <tbody class="">
                                            <tr>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="form.cycle_1" @change="checkCheckbox(1)" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Cycle 1</td>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="form.cycle_1_special" :disabled="!form.cycle_1" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Cycle 1 Special</td>
                                            </tr>
                                            <tr>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="form.cycle_2" @change="checkCheckbox(2)" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Cycle 2</td>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="form.cycle_2_special" :disabled="!form.cycle_2" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Cycle 2 Special</td>
                                            </tr>
                                            <tr>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="form.cycle_3" @change="checkCheckbox(3)" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Cycle 3</td>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="form.cycle_3_special" :disabled="!form.cycle_3" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Cycle 3 Special</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-xl-12 form-group text-right my-4">
                                    <a @click="$router.go(-1)" class="btn btn-outline-warning mr-2">Cancel</a>
                                    <input type="submit" class="btn btn-warning text-white" value="Submit">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: "CouponCreate",
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            form:{
                code:'',
                quota: '',
                cycle_1: false,
                cycle_2: false,
                cycle_3: false,
                cycle_1_special: false,
                cycle_2_special: false,
                cycle_3_special: false,
            },
        }
    },
    methods:{
        checkCheckbox(cycle) {
            if(cycle == 1) {
                if(this.form.cycle_1 == false) {
                    this.form.cycle_1_special = false
                }
            } else if (cycle == 2) {
                if(this.form.cycle_2 == false) {
                    this.form.cycle_2_special = false
                }
            } else if (cycle == 3) {
                if(this.form.cycle_3 == false) {
                    this.form.cycle_3_special = false
                }
            }
        },
        async postCoupon(){
            var data = {
                code : this.form.code,
                slug: this.paramsId,
                quota : this.form.quota,
                cycle_1: this.form.cycle_1,
                cycle_2: this.form.cycle_2,
                cycle_3: this.form.cycle_3,
                cycle_1_special: this.form.cycle_1_special,
                cycle_2_special: this.form.cycle_2_special,
                cycle_3_special: this.form.cycle_3_special,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/coupon`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Success Create New Coupon !',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'CouponCoursesAdmin' })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    // this.$toastr.error(this.validationErrors[0][0], 'failed Create New Course !');
                    this.$swal({
                        toast: true,
                        title: 'Failed Create New Coupon !',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>